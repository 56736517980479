import React from "react";
import axios from "axios";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

// helpers
import { validateEmail, validateName } from "./TextInput";

// components
import TextInput from "./TextInput";
import Button from "./Button";
import SelectInput from "./SelectInput";
import PhoneInput from "./PhoneInput";
import CountryInput from "./CountryInput";

// hooks
import usePhoneNumberUtils from "../hooks/usePhoneNumberUtils";

import config from '../config';
import ReCAPTCHA from 'react-google-recaptcha';

const FreeTrialForm = ({ pricingPackageState, handleCountryChange, device }) => {
  const { t, i18n } = useTranslation();
  const [formState, setFormState] = React.useState({
    restaurantName: '',
    businessType: 'food',
    managerName: '',
    email: '',
    phone: '',
    pricingPackage: 'standard',
    pricingPackageCodeOffer: '',
    country: "MA",
    forceValidation: false,
    submitting: false,
    isFormValid: false,

  });
  const [countryCode, setCountryCode] = React.useState(formState.country);
  const { validator: phoneValidator } = usePhoneNumberUtils({ country: countryCode });
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const recaptchaRef = React.useRef(null);

  const onFieldChange = React.useCallback((fieldName) => (event) => {
    setFormState({
      ...formState,
      [fieldName]: event.target.value
    });

    if (fieldName === "country") {
      handleCountryChange(event.target.value);
    }

    setSubmitted(false);

    if (successMessage) setSuccessMessage('');
  }, [formState, successMessage, handleCountryChange]);
  const forceValidation = (value) => setFormState({
    ...formState,
    forceValidation: value
  });
  const setSubmitting = (value) => setFormState({
    ...formState,
    submitting: value
  });

  const onSubmit = React.useCallback(async () => {
    const {
      restaurantName,
      managerName,
      businessType,
      email,
      phone,
      // pricingPackage,
      // pricingPackageCodeOffer,
      submitting,
      country
    } = formState;
    if (submitting) return;

    setErrorMessage('');
    let isFormValid = !!restaurantName && !!businessType && !!managerName && !!email && !!phone && !phoneValidator(phone) && validateEmail(email) && validateName(restaurantName);

    const reCaptchaToken = await recaptchaRef.current?.executeAsync();
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    if (!reCaptchaToken) {
      setErrorMessage(t("errors.captchaVerificationFailed"));
      return;
    }
    // if (pricingPackage === "custom" && !pricingPackageCodeOffer) {
    // let isFormValid = true;
    // }

    if (!isFormValid) {
      // force the validation to show the validation messages in the invalid fields
      forceValidation(true);
    } else {
      // call the backend
      setSubmitting(true);
      axios.post(`${config.GATSBY_API_URL}/monoRestaurant`, {
        restaurantName,
        businessType,
        managerName,
        email,
        phone,
        pricingPackage: pricingPackageState,
        // pricingPackageCodeOffer,
        country,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        language: i18n.language,
        reCaptchaToken: reCaptchaToken,
      }).then(() => {
        setSubmitting(false);
        setSubmitted(true);
        setSuccessMessage(t("freeTrial.accountCreated", {
          link: `${config.GATSBY_RESTAURANT_APP_URL}/#/login?firstTime=true&phone=${phone.replace('+', '')}&country=${countryCode}&lng=${i18n.language}`
        }))
        window.location.href = `${config.GATSBY_RESTAURANT_APP_URL}/#/login?firstTime=true&phone=${phone.replace('+', '')}&country=${countryCode}&lng=${i18n.language}`;
      }).catch((error) => {
        setSubmitting(false);
        setSuccessMessage('');
        if (error.response?.data?.error === 'RECAPTCHA_FAILED') {
          setErrorMessage(t("errors.captchaVerificationFailed"));
        } else {
          setErrorMessage(t("errors.phoneOrEmailAlreadyUsed"));
        }
      });
    }
  }, [countryCode, formState, phoneValidator, forceValidation]);
  const businessTypeOptions = React.useMemo(() => [
    {
      value: "food",
      name: t("freeTrial.businessTypes.food")
    },
    {
      value: "retail",
      name: t("freeTrial.businessTypes.retail")
    },
    {
      value: "other",
      name: t("freeTrial.businessTypes.other")
    },
  ], []);

  return (
    <div className="free-trial-form">
      <div className="flex flex-wrap flex-col h-full justify-between">
        <TextInput
          placeholder={t("freeTrial.restaurantName")}
          required
          type="name"
          value={formState.restaurantName}
          onChange={onFieldChange('restaurantName')}
          forceValidation={formState.forceValidation}
          className="sm:w-full sm:px-0"
          dataTestId={`restaurantName-${device}`}
        />
        <SelectInput
          value={formState.businessType}
          label=""
          className="sm:w-full sm:px-0"
          onChange={onFieldChange('businessType')}
          options={businessTypeOptions}
        />
        <TextInput
          placeholder={t("freeTrial.managerName")}
          required
          type="name"
          value={formState.managerName}
          onChange={onFieldChange('managerName')}
          forceValidation={formState.forceValidation}
          className="sm:w-full sm:px-0"
          dataTestId={`managerName-${device}`}
        />
        <TextInput
          placeholder={t("emailAddress")}
          required
          value={formState.email}
          onChange={onFieldChange('email')}
          forceValidation={formState.forceValidation}
          type="email"
          className="sm:w-full sm:px-0"
          dataTestId={`emailAddress-${device}`}
        />
        <CountryInput
          id="country"
          name="country"
          onChange={onFieldChange('country')}
          className="sm:w-full sm:px-0"
          forceValidation={formState.forceValidation}
          required
          value={formState.country}
        />
        <PhoneInput
          placeholder={t("phone")}
          id={`phone-${device}`}
          name="phone"
          value={formState.phone}
          country={formState.country}
          onChange={onFieldChange('phone')}
          className="sm:w-full sm:px-0"
          forceValidation={formState.forceValidation}
          setCountryCode={setCountryCode}
          countryCode={countryCode}
          required
        />


        <div className="termsBlock mb-6" dangerouslySetInnerHTML={{ __html: t("freeTrial.acceptTerm") }} />
        {
          formState.pricingPackage === "custom" && (
            <TextInput
              placeholder={t("freeTrial.codeOffer")}
              required
              value={formState.pricingPackageCodeOffer}
              onChange={onFieldChange('pricingPackageCodeOffer')}
              forceValidation={formState.forceValidation}
              className="sm:w-full sm:px-0"
            />
          )
        }
        <Button
          dataTestId={`Valider-${device}`}


          type="submit"
          className={`text-sm bg-primary text-white ${submitted ? 'opacity-50' : ''}`}
          ty
          size="organized"
          onClick={onSubmit}
          disabled={submitted}
        >
          <Trans i18nKey="freeTrial.confirm" />

        </Button>
        <ReCAPTCHA
          sitekey={config?.GATSBY_RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={recaptchaRef}
        />
      </div>
      {
        errorMessage && (
          <div className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 py-1">
            <p className="block mx-auto">{errorMessage}</p>
          </div>
        )
      }
      {
        successMessage && (
          <div className="flex items-center font-medium tracking-wide text-green-500 text-xs mt-1 ml-1 py-1">
            <p className="block mx-auto text-center" dangerouslySetInnerHTML={{ __html: successMessage }} data-testid="successMessage" ></p>
          </div>
        )
      }

    </div>
  );
};

export default React.memo(FreeTrialForm);